<ng-container *ngIf="{user: userState$ | async} as state">

    <ion-header>
        <ion-toolbar class="gb-top-toolbar">
            <ion-buttons slot="start">
                <ion-button (click)="closeModal()">
                    <ion-icon slot="icon-only" color="dark" name="close-outline"></ion-icon>
                </ion-button>
<!--                <ng-template #showBackButton>-->
<!--                    <ion-button (click)="resetForm(state?.user?.loginSignUpVisibleForm)">-->
<!--                        <ion-icon slot="icon-only" color="dark" name="chevron-back-outline"></ion-icon>-->
<!--                    </ion-button>-->
<!--                </ng-template>-->
            </ion-buttons>
            <ion-title>
                Login or sign up
            </ion-title>
        </ion-toolbar>
    </ion-header>

    <ion-content class="ion-padding" *ngIf="state.user as userState">
        <div class="container">
            <ion-spinner *ngIf="userState.loading; else loaded"
                         class="big-loading-spinner"
                         color="primary"
                         name="crescent"
                         duration="undefined"></ion-spinner>
        </div>
        <ng-template #loaded>
            <div class="ion-align-items-center">
                <div *ngIf="!userState.user?.uid || userState.user?.is_anonymous">

                    <app-phone-number-editor
                            *ngIf="userState.loginSignUpVisibleForm === LoginSignUpFormType.PHONE_LOGIN"
                            [showHeader]="false"
                    ></app-phone-number-editor>

                    <form [formGroup]="checkEmailForm"
                          (ngSubmit)="verifyEmail()"
                          class="login-form"
                          *ngIf="userState.loginSignUpVisibleForm === LoginSignUpFormType.CHECK_EMAIL">

                        <ion-input type="email"
                                   placeholder="Email"
                                   formControlName="email"
                                   class="form-input"></ion-input>
                        <ion-button color="primary"
                                    type="submit"
                                    class="ion-text-center ion-margin-top"
                                    expand="block"
                                    [disabled]="formHasErrors(checkEmailForm) || userState.loading">
                            <ion-spinner name="dots" duration="undefined" *ngIf="userState.loading else loaded"></ion-spinner>
                            <ng-template #loaded>
                                Next
                            </ng-template>
                        </ion-button>
                    </form>

                    <form [formGroup]="emailSignUpForm"
                          (ngSubmit)="signUpWithEmail()"
                          class="login-form"
                          *ngIf="userState.loginSignUpVisibleForm === LoginSignUpFormType.EMAIL_SIGN_UP">

                        <ion-input type="name"
                                   placeholder="Your name"
                                   formControlName="name"
                                   class="form-input"></ion-input>

                        <ion-input type="email"
                                   placeholder="Email"
                                   formControlName="email"
                                   class="form-input"></ion-input>

                        <ion-input type="password"
                                   placeholder="Password"
                                   formControlName="password"
                                   class="form-input"></ion-input>

                        <ion-item *ngFor="let validation of signUpFormValidationState" class="validation-item" lines="none">
                            <ion-icon slot="start"
                                      [color]="emailSignUpForm.controls['password'].hasError('required') || emailSignUpForm.controls['password'].hasError(validation.error_code)  ? 'danger' : 'success'"
                                      [name]="emailSignUpForm.controls['password'].hasError('required') ||
                                  emailSignUpForm.controls['password'].hasError(validation.error_code) ? 'close-circle-outline' :
                                                    'checkmark-circle-outline'"></ion-icon>
                            <ion-label [color]="emailSignUpForm.controls['password'].hasError('required') || emailSignUpForm.controls['password'].hasError(validation.error_code)  ? 'danger' : 'success'"
                                       class="ion-text-wrap">
                                {{validation.text}}
                            </ion-label>
                        </ion-item>

                        <ion-button color="primary"
                                    type="submit"
                                    class="ion-text-center ion-margin-top"
                                    expand="block"
                                    [disabled]="formHasErrors(emailSignUpForm) || userState.loading">
                            <ion-spinner name="dots" duration="undefined" *ngIf="userState.loading else loaded"></ion-spinner>
                            <ng-template #loaded>
                                Sign Up
                            </ng-template>
                        </ion-button>
                    </form>

                    <form [formGroup]="emailLoginForm"
                          (ngSubmit)="loginWithEmail()"
                          class="login-form"
                          *ngIf="userState.loginSignUpVisibleForm === LoginSignUpFormType.EMAIL_LOGIN">

                        <ion-input type="email"
                                   placeholder="Email"
                                   formControlName="email"
                                   readonly="true"
                                   class="form-input"></ion-input>

                        <ion-input type="password"
                                   placeholder="Password"
                                   formControlName="password"
                                   class="form-input"></ion-input>

                        <ion-item lines="none">
                            <ion-button slot="end" fill="clear" size="small" (click)="showResetPasswordForm()">
                                Forgot your password?
                            </ion-button>
                        </ion-item>

                        <ion-button color="primary"
                                    type="submit"
                                    class="ion-text-center ion-margin-top"
                                    expand="block"
                                    [disabled]="formHasErrors(emailLoginForm) || userState.loading">
                            <ion-spinner name="dots" duration="undefined" *ngIf="userState.loading else loaded"></ion-spinner>
                            <ng-template #loaded>
                                Log in
                            </ng-template>
                        </ion-button>
                    </form>

                    <form [formGroup]="resetPasswordForm"
                          (ngSubmit)="sendResetEmail()"
                          class="login-form ion-text-left ion-margin-top"
                          *ngIf="userState.loginSignUpVisibleForm === LoginSignUpFormType.RESET_PASSWORD">

                        <ion-label class="ion-margin-bottom ion-text-wrap">
                            Enter the email address associated with your account. We’ll send you a link to reset your password.
                        </ion-label>

                        <ion-input type="email"
                                   placeholder="Email"
                                   formControlName="email"
                                   class="form-input"></ion-input>

                        <ion-button color="primary"
                                    type="submit"
                                    class="ion-text-center ion-margin-top"
                                    expand="block"
                                    [disabled]="formHasErrors(resetPasswordForm) || userState.loading">
                            <ion-spinner name="dots" duration="undefined" *ngIf="userState.loading else loaded"></ion-spinner>
                            <ng-template #loaded>
                                Send reset link
                            </ng-template>
                        </ion-button>
                    </form>

                    <div class="ion-margin-top">
                        <ion-label class="gb-small-text-size" color="medium">
                            We use <a class="rzypro-link">RzyPro</a>
                            to manage the online order. By signing up or logging in, you agree to be subject to their
                            <a class="link-without-underline" (click)="openTerms()">
                                terms and conditions
                            </a>
                            and
                            <a class="link-without-underline" (click)="openPrivacy()">
                                privacy policy
                            </a>
                        </ion-label>
                    </div>

                    <div class="social-media-logins ion-text-center" *ngIf="supportedNonPhoneProviders$ | async">
<!--                        *ngIf="userState.loginSignUpVisibleForm === LoginSignUpFormType.CHECK_EMAIL-->
<!--            || userState.loginSignUpVisibleForm === LoginSignUpFormType.PHONE_LOGIN">-->

                        <div class="or-separator-container">
                            <ion-label class="gb-text-with-border or-separator" color="medium">
                                OR
                            </ion-label>
                        </div>

                        <ion-button color="dark" fill="outline"
                                    *ngIf="(showLoginAsGuest | async) === true"
                                    (click)="continueAsGuest()">
                            <ion-icon name="person" slot="start" class="button-logo"></ion-icon>
                            <ion-label>
                                Continue as Guest
                            </ion-label>
                        </ion-button>

                        <ion-button color="dark" fill="outline"
                                    *ngIf="showLoginWithApple"
                                    (click)="continueWithApple()">
                            <ion-icon name="logo-apple" slot="start" class="button-logo"></ion-icon>
                            <ion-label>
                                Continue with Apple
                            </ion-label>
                        </ion-button>
                        <ion-button color="dark"
                                    fill="outline"
                                    (click)="continueWithGoogle()">
                            <ion-icon name="logo-google" slot="start" class="button-logo"></ion-icon>
                            <ion-label>
                                Continue with Google
                            </ion-label>
                        </ion-button>
                        <ion-button color="dark"
                                    fill="outline"
                                    (click)="displayForm(LoginSignUpFormType.CHECK_EMAIL)"
                                    *ngIf="userState.loginSignUpVisibleForm !== LoginSignUpFormType.CHECK_EMAIL">
                            <ion-icon name="mail-outline" slot="start" class="button-logo"></ion-icon>
                            <ion-label>
                                Continue with Email
                            </ion-label>
                        </ion-button>
                        <ion-button color="dark"
                                    fill="outline"
                                    (click)="displayForm(LoginSignUpFormType.PHONE_LOGIN)"
                                    *ngIf="userState.loginSignUpVisibleForm !== LoginSignUpFormType.PHONE_LOGIN">
                            <ion-icon name="call-outline" slot="start" class="button-logo"></ion-icon>
                            <ion-label>
                                Continue with Phone
                            </ion-label>
                        </ion-button>
                    </div>
                </div>
            </div>
        </ng-template>

    </ion-content>
</ng-container>
